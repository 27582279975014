<script>
  import { repgen } from "@/firebase";
  //   import { saveAs } from "file-saver";

  import repgenSections from "@/data/RepgenSections";
  import Sections from "@/components/repgen/RepgenSections.vue";
import { collection, doc, getDoc, getDocs, updateDoc } from "@firebase/firestore";

  export default {
    name: "clientRepgen",
    components: {
      Sections,
    },
    data() {
      return {
        repgenSections: repgenSections,
        questionID: "",
        questionsRef: [],
        sections: [],
        xAxis: [],
        yAxis: [],
        zAxis: [],
        keyId: 0,
        export: [],
        copied: "",
        questionNum: 1,
        userName: "",
        archived: false
      };
    },
    mounted() {
      getDoc(doc(repgen, "clients", this.$route.params.clientID)).then(snapshot => {
        this.archived = snapshot.data().deleted;
        console.log(`Reading snapshot.deleted as ${snapshot.deleted}`)
      })
      this.$httpMS
        .get("/me/")
        .then((response) => {
          console.log(response.data);
          this.userName = response.data.givenName;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    methods: {
      copyUrl() {
        this.copied = "";

        this.copied = `https://akfapps.com/repgen/client/${this.$route.params.clientID}`;
        navigator.clipboard.writeText(this.copied);
      },
      updateSection() {
        this.keyId++;
        console.log("section emitted");
      },
      async exportFile() {
        this.export = [];
        var i;
        var title;

        for (i = 0; i < this.repgenSections.length; i++) {
          // title = "Scalability X Axis";
          title = this.repgenSections[i].title;
          const snapshot = await getDocs(collection(repgen, "clients", this.$route.params.clientID, title))
          snapshot.forEach((doc) => {
            var likert;
            if (doc.data().likert == "") {
              likert = " ";
            } else if (doc.data().likert == null) {
              likert = " ";
            } else {
              likert = doc.data().likert;
            }
            var commentTrail = "";
            var commentsLength;
            if (doc.data().comments) {
              commentsLength = doc.data().comments.length;
            } else {
              commentsLength = 0;
            }

            var comment;
            for (comment = 0; comment < commentsLength; comment++) {
              // commentTrail += doc.data().comments[comment].comment;
              commentTrail = "-";
              if (comment < commentsLength - 1) {
                commentTrail += "; ";
              }
            }

            var reco;
            if (doc.data().recommended) {
              reco = "Yes";
            } else {
              reco = "No";
            }

            this.export.push(doc.data().rowId + "\t");
            this.export.push(doc.data().question + "\t");
            this.export.push(likert + "\t");
            this.export.push(reco + "\t\t");
            this.export.push(commentTrail + "\n");
          });
        }

        var FileSaver = require("file-saver");
        var tsv = this.export.join("");
        var blob = new Blob([tsv], {
          type: "text/plain;charset=utf-8",
        });
        FileSaver.saveAs(blob, `${this.$route.params.clientID}.txt`);
      },
      async deleteClient() {
        updateDoc(doc(repgen, "clients", this.$route.params.clientID), {
          deleted: !this.archived,
        });
        this.$router.push("/");
      },
    },
  };
</script>

<template>
  <div class="mb-40">
    <div class="flex items-center space-x-12">
      <h1 class="text-4xl font-semibold text-blue-800 dark:text-blue-400">
        {{ $route.params.clientID }}
      </h1>
      <button @click="exportFile" class="flex items-center px-3 py-2 text-white bg-blue-500 rounded">
        <svg class="w-5 h-5 mr-2 fill-current" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M53.688 84.934l-44 10c-1.758.414-3.7-1.129-3.687-2.938V8.01c-.012-1.81 1.93-3.352 3.687-2.938l44 9.996c1.309.309 2.32 1.594 2.313 2.938v14c.023 1.585-1.415 3.043-3 3.043s-3.024-1.457-3-3.043V20.41l-38-8.625v76.43l38-8.625V67.997c-.024-1.586 1.414-3.043 3-3.043s3.023 1.457 3 3.043v14c-.012 1.41-1.11 2.656-2.313 2.937z"
          />
          <path
            d="M94 50.004c-.027.715-.324 1.543-.813 2.063l-16 17c-1.085.972-3.164 1.374-4.374.25-1.196-1.11-1.157-3.262.03-4.376l11.25-11.938H21a3 3 0 010-6h63.094l-11.25-11.938c-1.024-1.027-1.215-3.253-.031-4.375 1.18-1.12 3.335-.761 4.374.25l16 17c.547.583.813 1.266.813 2.063z"
          />
        </svg>
        Export
      </button>
      <button v-if="!archived" @click="deleteClient" class="flex items-center px-3 py-2 text-yellow-100 bg-yellow-500 rounded">
        <svg
              class="w-5 h-5 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
              />
            </svg>
        Archive Repgen
      </button>
      <button v-else @click="deleteClient" class="flex items-center px-3 py-2 text-green-100 bg-green-500 rounded">
        Make Repgen Active
      </button>
    </div>

    <div class="mt-3 font-semibold text-blue-500">
      <button @click="copyUrl()" class="flex items-center">
        <svg
          class="w-5 h-4 mr-1"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
          />
        </svg>
        Copy Url
      </button>
    </div>

    <div class="mt-6">
      <div class="flex items-end text-gray-600 border-b border-gray-300 dark:text-gray-400">
        <div class="w-6"></div>
        <div class="w-1/2 py-2 pr-4 text-center"></div>
        <div class="w-24 py-1 pl-16">Likert</div>
        <div class="w-16 py-1 pl-12">Reco</div>
        <div class="flex-1 py-1 pl-10">Comments</div>
      </div>

      <Sections
        v-for="(repgenSection, index) in repgenSections"
        :key="index"
        :section="repgenSection.title"
        :client="$route.params.clientID"
        :questionNum="questionNum"
        :userName="userName"
      />
    </div>
  </div>
</template>

<style></style>
